import React, {useState} from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import Nav from 'react-bootstrap/Nav'
import useSiteMetadata from "gatsby-theme-nieuwbouw/src/hooks/SiteMetadata";
import Logo from "gatsby-theme-nieuwbouw/src/components/Logo";
import Navbar from 'react-bootstrap/Navbar'
import Downloads from "gatsby-theme-nieuwbouw/src/components/project/Downloads";

interface MenuItem {
    link: string;
    external: boolean;
    name: string;
}

interface MenuItems {
    menuItems: MenuItem[]
}

export default () => {
    const data = useStaticQuery(graphql`
        query Brochure {
            project {
                documenten {
                    ...Document
                }
            }
        }
    `)
    const [downloadsOpen, setDownloadsOpen] = useState(false)
    const { menuItems }: MenuItems = useSiteMetadata()
    return (
        <>
            <Nav className={'ml-auto'}>
                {menuItems.slice(0,3).map((item: MenuItem, i: number) => (
                    <Nav.Item key={i}>
                        {item.external
                            ?
                            <Nav.Link href={item.link} rel="noopener noreferrer" target="_blank">{item.name}</Nav.Link>
                            :
                            <Link to={item.link} className="nav-link" activeClassName="active">{item.name}</Link>
                        }
                    </Nav.Item>
                ))
                }
            </Nav>
            <Navbar.Brand className={'d-none d-lg-block'}>
                <Link to="/">
                    <Logo title={'logo'} />
                </Link>
            </Navbar.Brand>
            <Nav className={'mr-auto'}>
                {menuItems.slice(3,7).map((item: MenuItem, i: number) => (
                    <Nav.Item key={i}>
                        {item.external
                            ?
                            <Nav.Link href={item.link} rel="noopener noreferrer" target="_blank">{item.name}</Nav.Link>
                            :
                            item.name === 'Inschrijven' ?
                            <Nav.Link disabled={true}>{item.name} (gesloten)</Nav.Link>
                            :
                            <Link to={item.link} className="nav-link" activeClassName="active">{item.name}</Link>
                        }
                    </Nav.Item>
                ))
                }
                <Nav.Item>
                    <Downloads project={data.project} isOpen={downloadsOpen} onRequestClose={() => setDownloadsOpen(false)} />
                    <Nav.Link onClick={() => setDownloadsOpen(true)}>
                        Downloads
                    </Nav.Link>
                </Nav.Item>
            </Nav>
        </>
    )
}
module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-41576983-8","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Binnengaats wonen","short_name":"Binnengaats wonen","start_url":"/","icon":"/codebuild/output/src234619546/src/binnengaatswonen.nl/src/img/icon.png","background_color":"#ffffff","theme_color":"#D75A27","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"04d339987e3a7890c1914d7b193baa91"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-nieuwbouw/gatsby-browser.js'),
      options: {"plugins":[],"referral":"binnengaatswonen.nl","objectcode":"N100023","afdelingscode":"907366","siteUrl":"https://www.binnengaatswonen.nl","trackingId":"UA-41576983-8","social":{"facebook":"","instagram":"","linkedin":""},"menuItems":[{"name":"Locatie","link":"/locatie","external":false},{"name":"Appartementen","link":"/appartementen","external":false},{"name":"Beschikbaar","link":"/beschikbaar","external":false},{"name":"Extra","link":"/extra","external":false},{"name":"Inschrijven","link":"/inschrijven","external":false}],"title":"Binnengaats wonen","titleShort":"Binnengaats wonen","background_color":"#ffffff","theme_color":"#D75A27"},
    }]

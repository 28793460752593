import React from "react"
import { Link } from "gatsby"
import Navbar from 'react-bootstrap/Navbar'
import useSiteMetadata from "gatsby-theme-nieuwbouw/src/hooks/SiteMetadata"
import Logo from "gatsby-theme-nieuwbouw/src/components/navbar/Logo"
import CollapseMenu from "./CollapseMenu"

interface Props {
  isHome: boolean
}

export default ({ isHome = false }: Props) => {
  const { title } = useSiteMetadata()

  return (
    <Navbar variant='light' expand='lg' collapseOnSelect={true}>
            <Navbar.Brand className={'mobile-brand d-lg-none'}>
                <Link to="/">
                    <Logo title={title} />
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className={'d-lg-flex justify-content-between'} id="basic-navbar-nav">
                <CollapseMenu />
            </Navbar.Collapse>
    </Navbar>
  )
}